HTML, #app, body {
    margin: 0 !important;
    display: inline-block !important;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

#root {
    height: 100vh;
}

.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
}
